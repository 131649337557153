import { createContext, useContext, useCallback, useRef, useState, useEffect } from 'react'
import type { ReactNode } from 'react'

import Dialog, { type DialogRef } from '~/components/Dialog'
import Button from '~/components/Button'

type ConfirmDialogOptions = {
  title?: string
  content: ReactNode
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void | Promise<void>
  onCancel?: () => void
  intent?: 'danger' | 'warning' | 'primary'
}

type ConfirmContextType = {
  confirm: (options: ConfirmDialogOptions) => void
}

const ConfirmContext = createContext<ConfirmContextType | null>(null)

export const ConfirmDialogProvider = ({ children }: { children: ReactNode }) => {
  const dialogRef = useRef<DialogRef>(null)
  const [dialogOptions, setDialogOptions] = useState<ConfirmDialogOptions | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const confirm = useCallback((options: ConfirmDialogOptions) => {
    setDialogOptions(options)
  }, [])

  // Use useEffect to open the dialog after dialogOptions is set and Dialog is rendered
  useEffect(() => {
    if (dialogOptions && dialogRef.current) {
      dialogRef.current.openDialog()
    }
  }, [dialogOptions])

  const handleConfirm = async () => {
    if (dialogOptions?.onConfirm) {
      setIsLoading(true)
      try {
        await dialogOptions.onConfirm()
      } finally {
        setIsLoading(false)
      }
    }
    dialogRef.current?.closeDialog()
    setDialogOptions(null) // Clear options after closing
  }

  const handleCancel = () => {
    dialogOptions?.onCancel?.()
    dialogRef.current?.closeDialog()
    setDialogOptions(null) // Clear options after closing
  }

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Dialog
        ref={dialogRef}
        title={dialogOptions?.title ?? 'Confirm'}
        position="central"
        contentProps={{
          onOpenAutoFocus: event => event.preventDefault()
        }}
        content={
          dialogOptions && (
            <div className="flex flex-col gap-6">
              <div>{dialogOptions.content}</div>
              <div className="flex justify-end gap-4 border-t border-light pt-6">
                <Button
                  text={dialogOptions.confirmText ?? 'Confirm'}
                  intent={dialogOptions.intent ?? 'primary'}
                  isLoading={isLoading}
                  onClick={handleConfirm}
                />
                <Button
                  text={dialogOptions.cancelText ?? 'Cancel'}
                  intent="outlined"
                  onClick={handleCancel}
                  isDisabled={isLoading}
                />
              </div>
            </div>
          )
        }
      />
    </ConfirmContext.Provider>
  )
}

export const useConfirmDialog = () => {
  const context = useContext(ConfirmContext)
  if (!context) {
    throw new Error('useConfirmDialog must be used within a ConfirmDialogProvider')
  }
  return context.confirm
}
